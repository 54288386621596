import appConfig from "@/config";
import {getCsrfToken} from "@/utility/helper";

export const sendFavorite = async (data) => {
  let res = await fetch(`${appConfig.apiEndPoint}/favorite`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': getCsrfToken(),
    },
    body: JSON.stringify(data)
  })
  return await res.json()
}

export const getShortList = async () => {
  let res = await fetch(`${appConfig.apiEndPoint}/shortlists`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': getCsrfToken(),
    },
  })
  let data = await res.json()
  return data?.data ?? [];
}
